import React, { Component } from "react";
import config from "react-global-configuration";
import axios from "axios";
import VideoModal from "../components/VideoModal";
import DocumentModal from "../components/DocumentModal";

class Attachments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videos: [],
      docs: [],
      selectedVideoIds: [],
      selectedDocIds: [],
      activeVideo: null,
      activeDocument: null,
      activeDocumentPath: "",
      showVideoModal: false,
      showDocumentModal: false,
      activeBrand: null,
    };
  }

  componentDidMount = () => {};

  componentDidUpdate(prevProps) {
    if (prevProps.bid !== null && prevProps.bid !== this.state.activeBrand) {
      this.setState(
        {
          activeBrand: prevProps.bid,
        },
        () => {
          this.getAttachments();
        }
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      document
        .getElementById("attachmentsContainer")
        .classList.contains("hidden") &&
      (this.state.selectedVideoIds.length > 0 ||
        this.state.selectedDocIds.length > 0)
    ) {
      this.setState({ selectedVideoIds: [], selectedDocIds: [] });

      const selectedEls = document.querySelectorAll(".checkbox-round");
      for (const el of selectedEls) {
        el.checked = false;
      }
    }
    return true;
  }

  getAttachments = () => {
    let that = this;
    let apiUrl = config.get("apiUrl");
    let token = localStorage.getItem("access_token");
    let bodyFormData = new FormData();
    bodyFormData.set("hID", "getVideosAndDocs");
    bodyFormData.set("uID", this.props.uid);
    bodyFormData.set("bID", this.state.activeBrand);

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        that.setState({
          docs: response.data.docs,
          videos: response.data.videos,
        });
      })
      .catch(function (response) {
        console.log("Error getting docs/videos");
      });
  };

  callReselectAttachments = (selectedDocs, selectedVids) => {
    this.setState({selectedDocIds: selectedDocs, selectedVideoIds: selectedVids});

    let i = 0;
    this.state.docs.forEach((doc) => {

      let docSelected = selectedDocs.filter(function (id) {
        if (id === doc.id) {
          return true;
        }
        return false;
      });
      if (docSelected.length > 0) {
        document.getElementById("document-check-" + doc.id).checked = true;
      } else {
        document.getElementById("document-check-" + doc.id).checked = false;
      }
      i = i + 1;
    });

    this.state.videos.forEach((vid) => {
      let vidSelected = selectedVids.filter(function (id) {
        if (id === vid.aID) {
          return true;
        }
        return false;
      });

      if (vidSelected.length > 0) {
        document.getElementById("video-check-" + vid.aID).checked = true;
      } else {
        document.getElementById("video-check-" + vid.aID).checked = false;
      }
      i = i + 1;
    });
  };

  handleSelect = (event, type, id, linkedId = 0) => {
    // selected doc ids or video ids
    // Videos may have a linkedId - in which case that linked video must be included with the one selected
    let selected = this.state[type];

    if (event.target.checked) {
      if (!selected.includes(id)) {
        selected.push(id);
      }
      if (linkedId > 0 && !selected.includes(linkedId)) {
        selected.push(linkedId);
        document.getElementById("video-check-" + linkedId).checked = true;
      }
    } else {
      // Check that its not linked to a video
      let linked = this.state.videos.filter((vid) => vid.linkedAID === id);
      if (
        linked.length > 0 &&
        document.getElementById("video-check-" + linked[0].aID).checked === true
      ) {
        document.getElementById("video-check-" + id).checked = true;
      } else {
        selected = selected.filter((selected) => selected !== id);
        // Uncheck the linked video
        // if (linkedId > 0) {
        //   selected = selected.filter((selected) => selected !== linkedId);
        //   document.getElementById("video-check-" + linkedId).checked = false;
        // }
      }
    }
    this.setState({ [type]: selected });

    // Update parent
    this.props.handleSelected(type, selected);
  };

  showVideo = (event, id) => {
    this.setState({ showVideoModal: true, activeVideo: id });
  };

  showDocument = (event, id) => {
    let newPath = this.state.docs[id].path;
    if (newPath.indexOf(".pdf") < 0) {
      newPath = newPath + ".pdf";
    }
    this.setState({
      showDocumentModal: true,
      activeDocument: id,
      activeDocumentPath: newPath,
    });
  };

  handleCloseVideoModal = () => {
    this.setState({ showVideoModal: false, activeVideo: null });
  };

  handleCloseDocumentModal = () => {
    this.setState({
      showDocumentModal: false,
      activeDocument: null,
      activeDocumentPath: "",
    });
  };

  render() {
    return (
      <div className="app__attachments">
        {this.state.activeVideo !== null && (
          <div className={`${!this.state.showVideoModal ? "hidden" : ""}`}>
            <VideoModal
              handleClose={this.handleCloseVideoModal}
              path={this.state.videos[this.state.activeVideo].path}
            />
          </div>
        )}

        {this.state.activeDocument !== null && (
          <div className={`${!this.state.activeDocumentPath ? "hidden" : ""}`}>
            <DocumentModal
              handleClose={this.handleCloseDocumentModal}
              path={this.state.activeDocumentPath}
            />
          </div>
        )}
        <span className="app__attachments__prompt">
          <b>Tap</b> to View or <b>Select</b> to Attach
        </span>
        <section className="app__attachments__images clearfix">
          {this.state.docs.map((row, key) => (
            <div
              className="app__attachments__image-container"
              key={"document-" + key}
            >
              <div className="app__attachments__header">
                <input
                  type="checkbox"
                  className="checkbox-round"
                  id={"document-check-" + row.id}
                  onClick={(event) =>
                    this.handleSelect(event, "selectedDocIds", row.id)
                  }
                />
              </div>
              <div
                className="app__attachments__image"
                style={{ backgroundImage: `url(${row.thumb})` }}
                onClick={(event) => this.showDocument(event, key)}
              ></div>
              <p>{row.name}</p>
            </div>
          ))}
        </section>
        <section className="app__attachments__videos clearfix">
          {this.state.videos.map((row, key) => (
            <div
              className="app__attachments__video-container"
              key={"video-" + key}
            >
              <div className="app__attachments__header">
                <input
                  type="checkbox"
                  className="checkbox-round"
                  id={"video-check-" + row.aID}
                  onClick={(event) =>
                    this.handleSelect(
                      event,
                      "selectedVideoIds",
                      row.aID,
                      row.linkedAID
                    )
                  }
                />
              </div>
              <div
                className="app__attachments__video"
                style={{ backgroundImage: `url(${row.thumb})` }}
                onClick={(event) => this.showVideo(event, key)}
              ></div>
              <p>{row.title}</p>
            </div>
          ))}
        </section>
      </div>
    );
  }
}

export default Attachments;
