import React, { Component } from "react";

class Modal extends Component {
  render() {
    return (
      <div className="app__modal" id="email-sent-modal">
        <div className="app__modal__container">
        {this.props.isPending && (
          <div className="app__modal__great-job"><p className="app__modal__text">Sending email...</p><img src="/img/loading.gif" alt="Loading" className="loading"/></div>
        )}

          {!this.props.isPending && (
              <div className="app__modal__great-job">
                <div className="app__modal__green-check check-circle"></div>
                <span className="app__modal__heading">Great job!</span>
                <p className="app__modal__text">Your email is on its way.</p>
                <div className="app__modal__icons">
                  <div
                    className="app__modal__icon-container"
                    onClick={this.props.handleNewEmail}
                  >
                    <div
                      className="app__modal__icon app__modal__icon--sendagain"
                      role="navigation"
                      aria-label="Send Another Email"
                    ></div>
                    <span
                      className="app__modal__icon-link"
                      role="navigation"
                      aria-label="Send Another Email"
                    >
                      Send Another Email
                    </span>
                  </div>
                  <div
                    className="app__modal__icon-container"
                    onClick={this.props.handleViewProfile}
                  >
                    <div
                      className="app__modal__icon app__modal__icon--review"
                      role="navigation"
                      aria-label="Review Analytics"
                    ></div>
                    <span
                      className="app__modal__icon-link"
                      role="navigation"
                      aria-label="Review Analytics"
                    >
                      Review Analytics
                    </span>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default Modal;
