import React, { Component } from "react";
import axios from "axios";
import config from "react-global-configuration";

class ContactModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMenu: "",
      selectedHcps: [],
      selectedMas: [],
      selectedHcpPrimary: 0,
      selectedMaPrimary: 0,
      hasSaved: false, // triggers a warning to the user that changes were made to the DB
      hasChangesToSave: false, // disable Save btn or not
      contact: null,
      origContact: null,
      errorMesssage: ""
    };
  }

  componentDidMount = () => {
    this.setState({
      contact: this.props.contact,
      origContact: this.props.contact,
    });

    if (this.props.profileMode) {
      this.setState({ activeMenu: "add" });
    } else {
      this.setState({ activeMenu: "select" });
    }
  };

  handleSelectHcp = (event, id) => {
    let contact = {...this.state.contact};
    let propName = event.target.id.replace("-check-", "_email_"); // e.g. hcp_email_1

    if (event.target.checked) {
      contact.includeHcps.push(propName);
    } else {
      contact.includeHcps = contact.includeHcps.filter(
        (item) => item !== propName
      );
    }
    this.setState({ contact: contact });
    console.log("contact", contact);
  };

  handleSelectMa = (event, id) => {
    let contact = {...this.state.contact};
    let propName = event.target.id.replace("-check-", "_email_"); // e.g. ma_email_1

    if (event.target.checked) {
      contact.includeMas.push(propName);
    } else {
      contact.includeMas = contact.includeMas.filter(
        (item) => item !== propName
      );
    }
    this.setState({ contact: contact });
    console.log("contact", contact);
  };

  handlePrimaryHCPChange = (event) => {
    console.log("handlePrimaryHCPChange", this.state.contact);
    let inputId = Number(event.target.value);
    let input = document.getElementById("hcp_email_" + inputId);

    if (input.value !== "" && input.value !== this.state.contact.email) {
      console.log("handlePrimaryHCPChange " + event.target.value);
      this.setState({ selectedHcpPrimary: Number(event.target.value), hasChangesToSave: true});
    } else if(input.value === this.state.contact.email && this.state.hasChangesToSave) {
      this.setState({ selectedHcpPrimary: Number(event.target.value), hasChangesToSave: false});
    }
  };

  onClickSave = () => {
    if (this.state.activeMenu === "primary") {
      // Save to server
      let input = document.getElementById(
        "hcp_email_" + this.state.selectedHcpPrimary
      );
      if (input.value !== this.state.contact.email) {
        let replaceEmail = this.state.contact.email;
        this.saveNewPrimary(input.value, replaceEmail, this.state.selectedHcpPrimary);
      }

    } else if (this.state.activeMenu === "add") {
      this.saveContactEdits();
    }
  };

  saveContactEdits = () => {
    let that = this;
    let apiUrl = config.get("apiUrl");
    let token = localStorage.getItem("access_token");
    let bodyFormData = new FormData();
    bodyFormData.set("hID", "updateContactDetails");
    bodyFormData.set("uID", this.props.uid);
    bodyFormData.set("contactId", this.state.contact.id);
    bodyFormData.set("details", JSON.stringify(this.state.contact));
    bodyFormData.set("originalDetails", JSON.stringify(this.state.origContact));

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if(response.data.status === "success") {
          console.log("update contact details success!");
          let contact = {...that.state.contact};
          that.setState({ origContact: contact, hasChangesToSave: false, hasSaved: true, errorMesssage: ""});
          console.log(that.state.contact, that.state.origContact);
        } else {
          this.setState({errorMesssage: "There was an error saving this contact."});
        }
      })
      .catch(function (response) {
        this.setState({errorMesssage: "There was an error saving this contact."});
      });
  }

  saveNewPrimary = (newPrimary, oldPrimary, replaceId) => {

    let that = this;
    let apiUrl = config.get("apiUrl");
    let token = localStorage.getItem("access_token");
    let bodyFormData = new FormData();
    bodyFormData.set("hID", "updateContactPrimaryEmail");
    bodyFormData.set("uID", this.props.uid);
    bodyFormData.set("contactId", this.state.contact.id);
    bodyFormData.set("newPrimary", newPrimary);
    bodyFormData.set("oldPrimary", oldPrimary);
    bodyFormData.set("replaceColName", "hcp_email_"+replaceId);

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        if(response.data.status === "success") {
          console.log("update primary success!");
          let aContact = {...that.state.contact};
          let bContact = {...that.state.contact};
          aContact.email = newPrimary;
          bContact.email = newPrimary;
          if(that.props.profileMode) {
            aContact.toEmail = newPrimary;
            bContact.toEmail = newPrimary;
          }
          bContact["hcp_email_" + that.state.selectedHcpPrimary] = oldPrimary;
          aContact["hcp_email_" + that.state.selectedHcpPrimary] = oldPrimary;
          that.setState({ contact: aContact, origContact: bContact, selectedHcpPrimary: 0, hasChangesToSave: false, hasSaved: true });
          document.getElementById('hcp-radio-0').checked = true;
        } else {
          this.setState({errorMesssage: "There was an error saving this contact."});
        }
        

      })
      .catch(function (response) {
        this.setState({errorMesssage: "There was an error saving this contact."});
      });
  }

  onHcpAddEdit = (event) => {
    let val = event.target.value;
    let aContact = {...this.state.contact};
    let colName = event.target.id;
    if(colName === 'hcp_email_0') {
      // Primary
      colName = 'email';
      if(val === "") {
        this.setState({errorMesssage: "Cannot delete the primary email address."});
        // cannot delete primary email
        return;
      }
    }

    if(colName === "ma_email_0" && val === "") {
      // cannot delete top MA if other MAs exist
      if(aContact.ma_email_1 !== "" || aContact.ma_email_1 !== "" || aContact.ma_email_1 !== "" || aContact.ma_email_1 !== "") {
        this.setState({errorMesssage: "Cannot delete the primary MA email address."});
        return;
      }
    }

    console.log("onHcpAddEdit " + event.target.id + ", " + colName);
    aContact[colName] = val;
    this.setState({ contact: aContact, hasChangesToSave: true, errorMesssage: "" });
  }

  goToSection = (name) => {
    if(this.state.activeMenu !== name) {
      let origContact = {...this.state.origContact};
      // TODO: warn user losing changes?
      this.setState({ contact: origContact, hasChangesToSave: false, selectedHcpPrimary: 0, activeMenu: name, errorMesssage: "" });
    }
  }

  onAddRecipient = () => {
    this.props.handleClose(false, true, this.state.contact);
  };

  render() {
    const hcpItems = [];
    const maItems = [];

    if (this.state.contact) {
      for (let i = 0; i < 5; i++) {
        let inputClass = "app__contact__input-text-email";
        if (i === 0) {
          inputClass += " primary";
        }

        let isEmailDisabled = this.state.activeMenu !== "add";
        let hcpVal = i === 0 ? this.state.contact.email : this.state.contact["hcp_email_" + i];
        let maVal = this.state.contact["ma_email_" + i];

        hcpItems.push(
          <label
            className="app__contact__input-text__label"
            key={"hcp-email-" + i}
          >
            {this.state.activeMenu === "select" && (
              <input
                onClick={(event) => this.handleSelectHcp(event, i)}
                type="checkbox"
                className="checkbox"
                id={"hcp-check-" + i}
                key={"hcp-check-" + i}
                disabled={i===0 || hcpVal === ""}
                defaultChecked={i === 0 || this.state.contact.includeHcps.includes("hcp_email_"+i)}
              />
            )}
            {this.state.activeMenu === "primary" && (
              <input
                type="radio"
                name="primaryHcp"
                value={i}
                id={"hcp-radio-"+i}
                disabled={hcpVal === ""}
                defaultChecked={this.state.selectedHcpPrimary === i}
                onChange={this.handlePrimaryHCPChange}
              />
            )}

            <input
              className={inputClass}
              type="text"
              id={"hcp_email_" + i}
              value={
                hcpVal
              }
              disabled={isEmailDisabled}
              onChange={this.onHcpAddEdit}
            ></input>
          </label>
        );

        maItems.push(
          <label
            className="app__contact__input-text__label"
            key={"ma-email-" + i}
          >
            {this.state.activeMenu === "select" && (
              <input
                onClick={(event) => this.handleSelectMa(event, 0)}
                type="checkbox"
                className="checkbox"
                id={"ma-check-" + i}
                key={"ma-check-" + i}
                disabled={maVal === "" || this.state.contact.maEmailCount >= 6}
                defaultChecked={this.state.contact.includeMas.includes("ma_email_"+i)}
              />
            )}
            <input
              id={"ma_email_" + i}
              className="app__contact__input-text-email"
              type="text"
              value={maVal}
              disabled={this.state.activeMenu !== "add"}
              onChange={this.onHcpAddEdit}
            ></input>
          </label>
        );
      }
    }

    return (
      <div>
      <div className="app__modal contact__modal title">
        <div className="app__modal__container">
          <p>Contact Card</p>
        </div>
      </div>
      <div className="app__modal contact__modal">
        {this.state.contact && (
          <div className="app__modal__container">
            <button
              className="close"
              onClick={(event) => {
                this.props.handleClose(this.state.hasSaved, false, this.state.contact);
              }}
            >
              x
            </button>

            <div className="app__contact__info-wrapper">
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  value={this.state.contact.firstName}
                  disabled
                ></input>
                <div className="app__contact__input-text__label-text">
                  First Name
                </div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  value={this.state.contact.lastName}
                  disabled
                ></input>
                <div className="app__contact__input-text__label-text">
                  Last Name
                </div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text specialty"
                  type="text"
                  value={this.state.contact.specialty}
                  disabled
                ></input>
                <div className="app__contact__input-text__label-text">
                  Specialty
                </div>
              </label>

              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text npi"
                  type="text"
                  value={this.state.contact.npi}
                  disabled
                ></input>
                <div className="app__contact__input-text__label-text">
                  NPI Number
                </div>
              </label>

              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  value={this.state.contact.address}
                  disabled
                ></input>
                <div className="app__contact__input-text__label-text">
                  Address
                </div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text"
                  type="text"
                  value={this.state.contact.city}
                  disabled
                ></input>
                <div className="app__contact__input-text__label-text">City</div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text state"
                  type="text"
                  value={this.state.contact.state}
                  disabled
                ></input>
                <div className="app__contact__input-text__label-text">
                  State
                </div>
              </label>
              <label className="app__contact__input-text__label">
                <input
                  className="app__contact__input-text zip"
                  type="text"
                  value={this.state.contact.zip}
                  disabled
                ></input>
                <div className="app__contact__input-text__label-text">Zip</div>
              </label>
            </div>

            <div className="app__contact__add-edit-wrapper">
              <div className="error">
                  {this.state.errorMesssage}
              </div>
              <div className="app__contact-add-edit-buttons">
                <button
                  onClick={(event) => {
                    this.goToSection("primary");
                  }}
                  className={`app__contact-add-edit ${
                    this.state.activeMenu === "primary" ? "active" : ""
                  } ${this.state.selectedPrimary}`}
                >
                  Set Primary
                </button>
                <button
                  onClick={(event) => {
                    this.goToSection("add");
                  }}
                  className={`app__contact-add-edit ${
                    this.state.activeMenu === "add" ? "active" : ""
                  }`}
                >
                  Add/Edit
                </button>{" "}
                <button
                  onClick={(event) => {
                    if (!this.props.profileMode) {
                      this.goToSection("select");
                    }
                  }}
                  className={`app__contact-add-edit ${
                    this.props.profileMode
                      ? "disabled"
                      : this.state.activeMenu === "select"
                      ? "active"
                      : ""
                  }`}
                >
                  Select
                </button>
              </div>
              <div className="app__contact__email-forms">
                <div className="app__contact__hcp-emails">
                  <h4>HCP Email Address</h4>
                  <form>{hcpItems}</form>
                </div>
                <div className="app__contact__ma-emails">
                  <h4>MA Email Address</h4>
                  <form>{maItems}</form>
                </div>
              </div>

              <div className="app__contact__footer">
                {this.state.activeMenu === "select" && (
                  <p className="app__contact__footer-info">
                    <span>Business rules to remember:</span>
                    Top spot indicates primary HCP email address.
                    <br />
                    Sending to an HCP and MAs defaults MA's addresses to a cc.
                    <br />
                    Only one HCP address can be selected. The additional
                    addresses with be cc'd.
                    <br />
                    Sending to an MA's email address automatically cc's HCP
                    primary email address.
                  </p>
                )}

                {(this.state.activeMenu === "add" ||
                  this.state.activeMenu === "primary") && (
                  <p className="app__contact__footer-info">
                    Top spot indicates primary HCP email address.
                  </p>
                )}
              </div>

              {this.state.activeMenu === "select" && (
                <button
                  className="app__contact-add"
                  onClick={(event) => {
                    this.onAddRecipient();
                  }}
                >
                  Add to Recipient List
                </button>
              )}
              {this.state.activeMenu !== "select" && (
                <button
                  disabled={!this.state.hasChangesToSave}
                  className="app__contact-save"
                  onClick={(event) => {
                    this.onClickSave();
                  }}
                >
                  Save
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      </div>
    );
  }
}

export default ContactModal;
