import "./polyfill.js";
import "url-search-params-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import config from "react-global-configuration";

// config.set( { apiUrl: process.env.REACT_APP_API_ROOT }, { freeze: false } );

// TODO: Update to use process.env on build - need to update package.json - it'll always be /web/api/ however...
config.set(
  {
   apiUrl: '/web/api/'
  },
  { freeze: false }
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
