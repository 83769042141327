import React, { Component } from "react";
import ReactPlayer from "react-player";

class VideoModal extends Component {
  render() {
    return (
      <div className="app__modal video__modal" id="email-sent-modal">
        <div className="app__modal__container">
          <button className="close" onClick={this.props.handleClose}>
            x
          </button>
          <ReactPlayer url={this.props.path} playing controls />
        </div>
      </div>
    );
  }
}

export default VideoModal;
