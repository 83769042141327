import React, { Component } from "react";
import PrivateApp from "./components/PrivateApp";
import SignIn from "./components/SignIn";
import Footer from "./components/Footer";
import config from "react-global-configuration";
import "./App.scss";

class App extends Component {
  constructor(props) {
    super(props);

	  // API base URL set in index.js

    this.state = {
      error: null,
      authed: false,
      user: {},
    };

    // let aUser = JSON.parse(localStorage.getItem("tmpUser"));
    // if(aUser.id !== null) {
    //   console.log("STILL LOGGED IN");
    //   this.setState({
    //     authed: true,
    //     user: aUser
    //   });

    //   // this.handleLogin(aUser, true);
    // } else {
    //   localStorage.removeItem("access_token");
    //   localStorage.removeItem("expire_at");
    //   localStorage.removeItem("tmpUser");
    // }

    localStorage.removeItem("access_token");
    localStorage.removeItem("expire_at");
    localStorage.removeItem("tmpUser");
  }

  handleLogin = (userData, isRefresh = false) => {
    let loggedInUser = {
      id: userData.id,
      name: userData.name,
      title: userData.title,
      phone: userData.phone,
      photo: userData.photo,
      email: userData.email,
      region: userData.region
    };

    if (!isRefresh) {
      localStorage.setItem("access_token", userData.jwt);
      localStorage.setItem("expire_at", userData.expire_at);
      localStorage.setItem("tmpUser", JSON.stringify(loggedInUser));
    }

    this.setState({
      authed: true,
      user: loggedInUser,
    });
  };

  handleLogout = () => {
    this.setState({ authed: false, user: null });

    localStorage.removeItem("access_token");
    localStorage.removeItem("expire_at");
    localStorage.removeItem("tmpUser");

  };

  handleUserUpdated = (newDetails) => {
    let newUser = {
      id: this.state.user.id,
      name: newDetails.fullname,
      title: newDetails.title,
      phone: newDetails.phone,
      photo: this.state.user.photo,
      email: this.state.user.email,
      region: this.state.user.region
    };

    this.setState({ user: newUser });
  };

  render() {

    if (this.state.authed) {
      return (
        <PrivateApp
          onLogout={this.handleLogout}
          user={this.state.user}
          handleUserUpdated={this.handleUserUpdated}
        />
        
      );
    } else {
      return (
        <div className="collegium app admin">
          <div className="landscapeOverlay">
            <p>Small mobile devices are not supported.</p>
          </div>
          <header className="app__header"></header>
          <div className="app__container">
            <SignIn onLogin={this.handleLogin} />
          </div>
          <Footer />
        </div>
      );
    }
  }
}

export default App;
