import React, { Component } from "react";
import config from "react-global-configuration";
import axios from "axios";
import CustomSelect from "../components/CustomSelect";

class EditTemplate extends Component {


  constructor(props) {
    super(props);

    this.state = {
      templateId: null,
      subjects: [],
      subtexts: [],
      greetings: [],
      location_setting: 0,
      contact_setting: 0,
      provider_setting: 0,
      date_setting: 0,
      intros: [],
      closings: [],
      onSubject: "",
      onGreeting: "",
      onIntro: "",
      onClosing: "",
      onLocation: "",
      onContact: "",
      onProvider: "",
      onDate: "",
      field_error: false,
    };
  }



  componentDidUpdate(prevProps) {
    this.checkForCompleteFields();
    //alert('mount');
    console.log(this.props.templates);
    if (
      prevProps.templates.length > 0 &&
      prevProps.activeTemplateIndex !== null &&
      prevProps.activeTemplateIndex >= 0
    ) {
      let tID = prevProps.templates[prevProps.activeTemplateIndex].id;

      if (tID && tID !== this.state.templateId) {
        this.setState(
          {
            templateId: tID,
            location_setting: this.props.templates[prevProps.activeTemplateIndex].location_field,
            contact_setting: this.props.templates[prevProps.activeTemplateIndex].contact_field,
            provider_setting: this.props.templates[prevProps.activeTemplateIndex].provider_field,
            date_setting: this.props.templates[prevProps.activeTemplateIndex].date_field,
          },
          () => {
            this.getTemplateOptions();
            this.getTemplatePreview(false);
          }
        );
      }

    } else if(prevProps.activeTemplateIndex === -1 && this.state.templateId !== -1) {
      // Force a reset
      this.setState({templateId: -1});
    }

     //console.log(this.props.templates[0].location_field);
     
  }

  componentDidMount = () => {

    this.checkForCompleteFields();
  };

  checkForCompleteFields = () => {
    let err = false;
    if(this.state.location_setting==1 && this.state.onLocation == "") {
      err = true;
    } else if(this.state.contact_setting==1 && this.state.onContact == "") {
      err = true;
    } else if(this.state.provider_setting==1 && this.state.onProvider == "") {
      err = true;
    } else if(this.state.date_setting==1 && this.state.onDate == "") {
      err = true;
    }
    if(this.state.field_error !== err) {
      this.setState({field_error: err});
      this.props.setDisabledNext(err);
    }
    
  }

  handlePreview = (event) => {
    if(!this.state.field_error){
    this.getTemplatePreview(true);
    } 
  };

  optionSelect = (type, val) => {
    this.setState(
      {
        [type]: val,
      },
      () => {
        let details = {
          subject: this.state.onSubject,
          subtext: this.state.subtexts[this.state.onSubject],
          greeting: this.state.onGreeting,
          intro: this.state.onIntro,
          close: this.state.onClosing,
          location: this.state.onLocation,
          contact: this.state.onContact,
          provider: this.state.onProvider,
          date: this.state.onDate,
        };
        console.log(details);
        this.props.handleOptionChange(details);
      }
    );
  };

  getTemplatePreview = (doContentReplace = false) => {
    let apiUrl = config.get("apiUrl");
    let token = localStorage.getItem("access_token");
    let bodyFormData = new FormData();
    let details = {};

    if(this.state.templateId === -1) {
      return;
    }
    
    bodyFormData.set("hID", "getTemplatePreview");
    bodyFormData.set("uID", this.props.user.id);
    bodyFormData.set("bID", this.props.bid);
    bodyFormData.set("tID", this.state.templateId);

    if (doContentReplace) {
      details = {
        subject: this.state.onSubject,
        subtext: this.state.subtexts[this.state.onSubject],
        greeting: this.state.onGreeting,
        intro: this.state.onIntro,
        close: this.state.onClosing,
        location: this.state.onLocation,
        contact: this.state.onContact,
        provider: this.state.onProvider,
        date: this.state.onDate,
      };
      bodyFormData.set("details", JSON.stringify(details));
    }

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        console.log(response);
        let el = document.getElementById("previewHtmlEdit");
        el.contentDocument.body.innerHTML = response.data.result;

        let links = el.contentDocument.body.getElementsByTagName('a');
        for(var i = 0, len = links.length; i < len; i++) {
          links[i].onclick = function () {
              console.log("blocked link in edit preview");
              return false;
            }
        }

        el.contentDocument.body.scrollTo(0, 0);
      })
      .catch(function (response) {
        console.log("Error getting template options");
      });
  };

  updateTextarea = (e, field) => {
    this.setState(
      {
        [field]: e.target.value
      },
      () => {
        let details = {
          subject: this.state.onSubject,
          subtext: this.state.subtexts[this.state.onSubject],
          greeting: this.state.onGreeting,
          intro: this.state.onIntro,
          close: this.state.onClosing,
          location: this.state.onLocation,
          contact: this.state.onContact,
          provider: this.state.onProvider,
          date: this.state.onDate,
        };
        console.log(details);
        this.props.handleOptionChange(details);
      }
    );
  }

  getTemplateOptions = () => {
    let that = this;
    let apiUrl = config.get("apiUrl");
    let token = localStorage.getItem("access_token");
    let bodyFormData = new FormData();
    bodyFormData.set("hID", "getTemplateOptions");
    bodyFormData.set("uID", this.props.user.id);
    bodyFormData.set("tID", this.state.templateId);

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        that.setState({
          subjects: response.data.result.subjects,
          subtexts: response.data.result.subtexts,
          greetings: response.data.result.greetings,
          intros: response.data.result.intros,
          closings: response.data.result.closings,
          onSubject: response.data.result.subjects[0],
          onGreeting: response.data.result.greetings[0],
          onIntro: response.data.result.intros[0],
          onClosing: response.data.result.closings[0],
        });

        // Pass default select values to parent
        let details = {
          subject: response.data.result.subjects[0],
          subtext:
            response.data.result.subtexts[response.data.result.subjects[0]],
          greeting: response.data.result.greetings[0],
          intro: response.data.result.intros[0],
          close: response.data.result.closings[0],
          location: that.state.onLocation,
          contact: that.state.onContact,
          provider: that.state.onProvider,
          date: that.state.onDate,
        };

        that.props.handleOptionChange(details);
      })
      .catch(function (response) {
        console.log("Error getting template options");
      });
  };

  render() {
    return (
      <section
        className="app__select-topic"
        key={`template-` + this.props.activeTemplateIndex}
      >
        <label>Subject Line</label>
        <CustomSelect
          type="onSubject"
          options={this.state.subjects}
          onSelect={this.optionSelect}
        />
        <div className="app__dropdowns unselectable">
          <label>Greeting</label>
          <CustomSelect
            type="onGreeting"
            options={this.state.greetings}
            onSelect={this.optionSelect}
          />
         {this.props.bid !== 3 && (
           <div>
          <label>Introduction</label>
          <CustomSelect
            type="onIntro"
            options={this.state.intros}
            onSelect={this.optionSelect}
          />
          <label>Closing</label>
          <CustomSelect
            type="onClosing"
            options={this.state.closings}
            onSelect={this.optionSelect}
          />
          </div>
         )}
         {this.state.location_setting == 1 &&
          <React.Fragment>
         <label>Location<sup>*</sup></label>
          <textarea placeholder="Add location" maxLength="100" className="input__textarea" onChange={(event) => this.updateTextarea(event, "onLocation")}></textarea>
          </React.Fragment>
        }
          {this.state.contact_setting == 1 &&
          <React.Fragment>
         <label>Contact<sup>*</sup></label>
          <textarea placeholder="Add the name of the point of contact for setting up meetings" maxLength="100" className="input__textarea" onChange={(event) => this.updateTextarea(event, "onContact")}></textarea>
          </React.Fragment>
        }
        {this.state.provider_setting == 1 &&
          <React.Fragment>
         <label>Provider<sup>*</sup></label>
          <textarea placeholder="Add who you want to meet with by name" maxLength="100" className="input__textarea" onChange={(event) => this.updateTextarea(event, "onProvider")}></textarea>
          </React.Fragment>
        }
        {this.state.date_setting == 1 &&
          <React.Fragment>
         <label>Date<sup>*</sup></label>
         <textarea placeholder="Provide meeting date(s) and time(s)" maxLength="100" className="input__textarea" onChange={(event) => this.updateTextarea(event, "onDate")}></textarea>
          </React.Fragment>
        }
        {(this.state.date_setting == 1 || this.state.location_setting == 1 || this.state.contact_setting == 1 || this.state.provider_setting == 1) &&
          <p className="text__small"><sup>*</sup>required fields, maximum character limit of 100</p>
        }
        {this.state.field_error &&
          <div className="app__required-warning">Please make sure all the required fields are filled out before you continue.</div>
        }
          <br/>
          <button
            className="app__select-topic__button"
            onClick={(event) => this.handlePreview(event)}
          >
            Preview My Email
          </button>
        </div>
        <div className="app__email-preview app__center">
          <iframe
            title="Preview"
            width="665px"
            height="865px"
            className="templatePreview"
            id="previewHtmlEdit"
          ></iframe>
        </div>
      </section>
    );
  }
}

export default EditTemplate;
