import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="app__footer">
        <div className="app__footer__container">
          <div className="app__footer__logo"></div>
          <p className="app__footer__smalltext">
            © 2021 Collegium Pharmaceutical, Inc. All rights reserved.
            PP-XT-US-1507 06/21
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
