import React, { Component } from "react";

class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      listDown: false,
    };
    this.popDropDown = this.popDropDown.bind(this);
    this.selectItem = this.selectItem.bind(this);
  }

  popDropDown = (e) => {
    if (this.state.listDown) {
      this.setState({ listDown: false });
    } else {
      this.setState({ listDown: true });
    }
  };

  collapse = (e) => {
    this.setState({listDown: false});
    e.target.parentNode.parentNode.parentNode.blur();
  }

  handleClickOutside(){
    this.setState({
      listDown: false
    })
  }

  selectItem = (e, i, item) => {
    this.setState({
      listDown: false,
      selected: i,
    });
    
    if(this.props.type === "recipientEmail") {
      this.props.onSelect(item, this.props.contact);
    } else {
      this.props.onSelect(this.props.type, item);
    }
    
    e.target.parentNode.parentNode.parentNode.blur();
  };

  chop = (st, len) => {
    return len <= st.length ? st.substr(0, len) + "..." : st;
  };

  render() {
    const items = this.props.options.map((item, index) => (
      <li className="unselectable" onClick={(event) => this.selectItem(event, index, item)} key={index}>
        {item}
      </li>
    ));

    if (this.props.options.length > 0) {
      return (
        <div
          className={`app__custom-select ${
            this.props.type === "onSubject" ? "subject" : this.props.type === "recipientEmail" ? "recipient-select" : ""
          }`} tabIndex="0" onBlur={(event) => { if(!this.props.isDisabled) { this.collapse(event); }}} onClick={(event) => { if(!this.props.isDisabled) { this.popDropDown(event); }}}
        >
          <div className="app__custom-select__main unselectable">
            <p>{this.chop(this.props.options[this.state.selected], 52)}</p>
          </div>
          <div className="app__custom-select__list"
          style={{ height: this.state.listDown ? "350px" : "0" }}
          >
            <div
              className="app__custom-select__list-items"
              style={{ top: this.state.listDown ? "0" : "-350px" }}
            >
              {items}
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default CustomSelect;
