import React, { Component } from "react";
import config from "react-global-configuration";
import axios from "axios";
import ContactModal from "../components/ContactModal";
import CustomSelect from "../components/CustomSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faEdit } from "@fortawesome/free-solid-svg-icons";

class Recipients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contacts: [],
      origContacts: [],
      selectedContacts: [],
      searchTerm: "",
      allSelected: false,
      ccAltEmailsSelected: false,
      ccMasSelected: false,
      masOnlySelected: false,
      sortDir: "asc",
      didReset: true,
      showContactCardModal: true,
      showWarningModal: false,
      sendView: 'all', // all or distribution
      editingContact: null,
      distributionName: '',
      distributionView: 'save', // save or view
      distributionGroups: [],//[{ id: 1, name: "test", npis: ["2345678912", "1659328987", "1376035584"]}, {id: 2, name: "testagain", npis: ["0000000", "1992761910"]}],
      selectedDistributionGroups: [],
      showDistributionWarningModal: false,
      showDistributionDeleteModal: false,
      showDistributionCreatedModal: false,
      groupToDelete: null, // id
      groupObjectToEdit: null, // full object
      showClearSelectionsModal: false
    };
  }

  callReset() {
    this.getContacts();
  }

  componentDidMount = () => {
    if (this.props.profileMode || this.props.distributionMode) {
      this.getContacts();
    }
  };

  getContacts = () => {
    console.log("getContacts");
    let that = this;
    let apiUrl = config.get("apiUrl");
    let token = localStorage.getItem("access_token");
    let bodyFormData = new FormData();
    bodyFormData.set("hID", "getContacts");
    bodyFormData.set("uID", this.props.uid);
    bodyFormData.set("region", this.props.repRegion); // 0000000 admin users

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        console.log("contacts", response.data.contacts);
        console.log("groups", response.data.distGroups);

        let i = 0;
        let contacts = response.data.contacts;
        let groups = response.data.distGroups;
        contacts.forEach((contact) => {
          if (document.getElementById("contact-" + i)) {
            document.getElementById("contact-" + i).checked = false;
            document.getElementById("ccma-" + i).checked = false;
            i = i + 1;
          }
        });

        that.setState({
          contacts: contacts,
          origContacts: contacts,
          distributionGroups: groups,
          doReset: false,
          selectedContacts: [],
          selectedDistributionGroups: [],
          allSelected: false,
          sendView: 'all',
        });
      })
      .catch(function (response) {
        console.log("Error getting contacts");
      });
  };

  handleSelectCcmasDistributionGroup = (event, index) => {
    let selected = [...this.state.selectedContacts];
    let i = 0;

    // Update selected contacts in this dist group to enable ccmas
    if (event.target.checked) {
      selected.forEach((selected) => {
        if(this.state.distributionGroups[index].npis.includes(selected.npi)) {
          selected.ccmas = true;
        }
        i = i + 1;
      });

    } else {
      // CCMAs should be false for each selected in this dist group
      selected.forEach((selected) => {
        if(this.state.distributionGroups[index].npis.includes(selected.npi)) {
          selected.ccmas = false;
        }
        i = i + 1;
      });
    }

    console.log("selectedContacts ccmas from distribution group", selected, index);

    this.setState({ selectedContacts: selected });
    // Update parent
    this.props.handleRecipientsUpdated(selected);
  }

  handleSelectDistributionGroup = (event, index) => {
    let selected = [...this.state.selectedContacts];
    let contacts = [...this.state.contacts];
    let selectedGroups = [...this.state.selectedDistributionGroups];
    let i = 0;

    // Loop through NPIs in this distribution group, and
    // select/deselect them in All view + selectedContacts var
    if (event.target.checked) {
      selectedGroups.push(index);
      contacts.forEach((contact) => {
        if (contact.hcpEmailCount < 6) {
          if(this.state.distributionGroups[index].npis.includes(contact.npi)) {
            contact.ccmas = false;
            contact.ccalts = false;
            contact.masonly = false;
            contact.includeHcps = [];
            contact.includeMas = [];
            contact.toEmail = contact.email;
            selected.push(contact);
          }
        }
        i = i + 1;
      });
    } else {
      // Removed from selectedGroups and selected groups
      selectedGroups.pop(index);
      document.getElementById("dist-ccmas-" + index).checked = false;
      selected = selected.filter(
        (selected) => !this.state.distributionGroups[index].npis.includes(selected.npi)
      );
    }

    console.log("selectedContacts from distribution group", selected, index);

    this.setState({ selectedContacts: selected, selectedDistributionGroups: selectedGroups });
    // Update parent
    this.props.handleRecipientsUpdated(selected);
  }

  // Selecting Recipient or checking off CC MA's for a recipient (type)
  handleSelect = (event, index, type) => {
    let selected = [...this.state.selectedContacts];
    let contacts = [...this.state.contacts];

    // Select Contact
    if (type === "contact") {
      if (event.target.checked) {
        selected.push(this.state.contacts[index]);
      } else {
        // Deselect CCMA as well
        // contacts[index].ccmas = false;
        // console.log("contactsnew", contacts);
        selected = selected.filter(
          (selected) => selected !== this.state.contacts[index]
        );
      }

      this.setState({ selectedContacts: selected, contacts: contacts });
      // Update parent
      this.props.handleRecipientsUpdated(selected);
    } else if (
      type === "ccma" &&
      document.getElementById("contact-" + index).checked === true
    ) {
      console.log("ccma select");
      const ind = selected.findIndex(
        (e) => e.id === this.state.contacts[index].id
      );
      selected[ind].ccmas = event.target.checked;

      this.setState({ selectedContacts: selected });
      // Update parent
      this.props.handleRecipientsUpdated(selected);
    }

    console.log("selectedContacts", selected, index);
  };

  selectAll = (event) => {
    let selected = [];
    let contacts = [...this.state.contacts];
    let i = 0;

    if (!this.state.allSelected) {
      // TODO: reset chosen dropdown to "primary"
      // TODO: see if any other group selector is activated. If so, apply them to each contact as well
      // ccMasSelected ccAltEmailsSelected masOnlySelected
      contacts.forEach((contact) => {
        if (!document.getElementById("contact-" + i).disabled && contact.hcpEmailCount < 6) {
          contact.ccmas = this.state.ccMasSelected && contact.maEmailCount < 6;
          contact.ccalts = this.state.ccAltEmailsSelected;
          contact.masonly = this.state.masOnlySelected && contact.maEmailCount < 6;
          contact.includeHcps = [];
          contact.includeMas = [];
          contact.toEmail = contact.email;
          selected.push(contact);
          document.getElementById("contact-" + i).checked = true;
          if (this.state.ccMasSelected && contact.maEmailCount < 6) {
            document.getElementById("ccma-" + i).checked = true;
          }
        }
        i = i + 1;
      });
      this.setState({ selectedContacts: selected, allSelected: true });
    } else {
      // Deselect and reset any group actions
      // let newContacts = [...this.state.origContacts] ?
      contacts.forEach((contact) => {
        contact.ccmas = false;
        contact.ccalts = false;
        contact.masonly = false;
        document.getElementById("contact-" + i).checked = false;
        document.getElementById("ccma-" + i).checked = false;
        i = i + 1;
      });
      this.setState({
        selectedContacts: selected,
        allSelected: false,
        masOnlySelected: false,
        ccMasSelected: false,
        ccAltEmailsSelected: false,
      });
    }
    // Update parent
    this.props.handleRecipientsUpdated(selected);
  };

  sortArray = (event, type) => {
    const types = {
      firstName: "firstName",
      lastName: "lastName",
      specialty: "specialty",
      city: "city",
      state: "state",
      zip: "zip",
    };

    let sorted;
    let newSortDir = "desc";
    const sortProperty = types[type];

    if (this.state.sortDir === "asc") {
      sorted = this.state.contacts.sort((a, b) =>
        a[sortProperty] > b[sortProperty] ? 1 : -1
      );
    } else {
      sorted = this.state.contacts.sort((a, b) =>
        a[sortProperty] > b[sortProperty] ? -1 : 1
      );
      newSortDir = "asc";
    }

    // Update selections
    this.setState(
      {
        contacts: sorted, sortDir: newSortDir 
      },
      () => {
        this.recheckContacts();
      }
    );
  };

  // sortDistributionArray = (event, type, view) => {
  //   const types = {
  //     name: "name"
  //   };

  //   let sorted;
  //   let newSortDir = "desc";
  //   const sortProperty = types[type];

  //   if (this.state.sortDir === "asc") {
  //     sorted = this.state.distributionGroups.sort((a, b) =>
  //       a[sortProperty] > b[sortProperty] ? 1 : -1
  //     );
  //   } else {
  //     sorted = this.state.distributionGroups.sort((a, b) =>
  //       a[sortProperty] > b[sortProperty] ? -1 : 1
  //     );
  //     newSortDir = "asc";
  //   }

  //   // Update selections
  //   this.setState(
  //     {
  //       distributionGroups: sorted, sortDir: newSortDir 
  //     },
  //     () => {
  //       if(view === "compose") {
  //         // TODO: add in this feature later perhaps
  //       }
  //     }
  //   );
  // };

  recheckContacts = () => {
    // Ensure inputs of selected are re-checked
    let i = 0;
    this.state.contacts.forEach((contact) => {
      let contactSelected = this.state.selectedContacts.filter(function (
        item
      ) {
        if (item["id"] === contact["id"]) {
          return true;
        }
        return false;
      });
      document.getElementById("contact-" + i).checked =
        contactSelected.length > 0;

      let ccmaSelected = this.state.selectedContacts.filter(function (
        item
      ) {
        if (item["id"] === contact["id"] && item["ccmas"]) {
          return true;
        }
        return false;
      });
      document.getElementById("ccma-" + i).checked =
        ccmaSelected.length > 0;

      i = i + 1;
    });
  }

  handleSearchKeyUp = (event) => {
    let searchVal = event.currentTarget.value;
    let filter = {
      firstName: searchVal,
      lastName: searchVal,
      specialty: searchVal,
      email: searchVal,
      city: searchVal,
      state: searchVal,
      zip: searchVal,
    };

    if (searchVal.trim() === "") {
      this.setState(
        {
          contacts: this.state.origContacts,
        },
        () => {
          this.recheckContacts();
        }
      );
      return;
    }

    let contacts = this.state.origContacts.filter(function (item) {
      for (var key in filter) {
        if (item[key].toLowerCase().indexOf(filter[key].toLowerCase()) >= 0)
          return true;
      }
      return false;
    });

    this.setState(
      {
        contacts: contacts,
      },
      () => {
        // Ensure inputs of selected are re-checked
        let i = 0;
        this.state.contacts.forEach((contact) => {
          let contactSelected = this.state.selectedContacts.filter(function (
            item
          ) {
            if (item["id"] === contact["id"]) {
              return true;
            }
            return false;
          });
          document.getElementById("contact-" + i).checked =
            contactSelected.length > 0;

          let ccmaSelected = this.state.selectedContacts.filter(function (
            item
          ) {
            if (item["id"] === contact["id"] && item["ccmas"]) {
              return true;
            }
            return false;
          });
          document.getElementById("ccma-" + i).checked =
            ccmaSelected.length > 0;

          i = i + 1;
        });
      }
    );
  };

  closeContactCardModal = (didSave, didUpdateRecipientList, updatedContact) => {
    let newContacts = [...this.state.origContacts];
    let selected = [...this.state.selectedContacts];
    const selectedIndex = selected.findIndex((e) => e.id === updatedContact.id);
    if (didSave || didUpdateRecipientList) {
      const index = newContacts.findIndex((e) => e.id === updatedContact.id);
      newContacts[index] = updatedContact;

      if (selectedIndex >= 0) {
        selected[selectedIndex] = updatedContact;
      }

      // If the user had called a Save action on the Contact Card, warn of DB overwrites
      this.setState({
        showContactCardModal: false,
        showWarningModal: didSave,
        contacts: newContacts,
        origContacts: newContacts,
        selectedContacts: selected,
      });

      // Update parent of selected contact changes
      this.props.handleRecipientsUpdated(selected);
    } else {
      this.setState({
        showContactCardModal: false,
        showWarningModal: didSave
      });
    }
  };

  clickAllPrimary = (e) => {
    // TODO: reset all dropdowns to select primary email address
    this.selectAll(null);
  };

  clickCCAltEmails = (e) => {
    let ccAltEmailsSelected = !this.state.ccAltEmailsSelected;

    let selected = [...this.state.selectedContacts];
    if (selected.length > 0) {
      selected.forEach((contact) => {
        contact.ccalts = ccAltEmailsSelected;

        // Clear out individual selects when group selection is active
        if (ccAltEmailsSelected) {
          contact.includeHcps = [];
          contact.includeMas = [];
          contact.toEmail = contact.email;
        }
      });

      this.setState({
        selectedContacts: selected,
        ccAltEmailsSelected: ccAltEmailsSelected,
      });
      console.log("clickCCAltEmails", selected);
    }
  };

  clickCCMAs = (e) => {
    // If MAs Only is active, deactivate and undo masonly for each selectedContact
    let ccMasSelected = !this.state.ccMasSelected;

    // Select/deselect CC MA for each selected contact (and contact)
    let selected = [...this.state.selectedContacts];
    if (selected.length > 0) {
      selected.forEach((contact) => {
        contact.ccmas = ccMasSelected && contact.maEmailCount < 6;
        contact.masonly = false;

        // Clear out individual selects when group selection is active
        if (ccMasSelected) {
          contact.includeHcps = [];
          contact.includeMas = [];
          contact.toEmail = contact.email;
        }
      });

      let i = 0;
      this.state.contacts.forEach((contact) => {
        let ccmaSelected = this.state.selectedContacts.filter(function (item) {
          if (item["id"] === contact["id"] && item["ccmas"]) {
            return true;
          }
          return false;
        });
        document.getElementById("ccma-" + i).checked = ccmaSelected.length > 0;

        i = i + 1;
      });

      this.setState({
        selectedContacts: selected,
        ccMasSelected: ccMasSelected,
        masOnlySelected: false,
      });
      console.log("clickCCMAs", selected);
    }
  };

  clickMAsOnly = (e) => {
    let masOnlySelected = !this.state.masOnlySelected;

    // Select/deselect CC MA for each selected contact (and contact)
    let selected = [...this.state.selectedContacts];
    if (selected.length > 0) {
      selected.forEach((contact) => {
        contact.ccmas = false;
        contact.masonly = masOnlySelected;

        // Clear out individual selects when group selection is active
        if (masOnlySelected) {
          contact.includeHcps = [];
          contact.includeMas = [];
          contact.toEmail = contact.email;
        }
      });

      let i = 0;
      this.state.contacts.forEach((contact) => {
        document.getElementById("ccma-" + i).checked = false;
        i = i + 1;
      });

      this.setState({
        selectedContacts: selected,
        masOnlySelected: masOnlySelected,
        ccMasSelected: false,
      });
      console.log("clickMAsOnly", selected);
    }
  };

  selectDropdownEmail = (newToEmail, contact) => {
    if (contact.toEmail !== newToEmail) {
      let updatedSelected = [...this.state.selectedContacts];
      const selectedIndex = updatedSelected.findIndex(
        (e) => e.id === contact.id
      );
      updatedSelected[selectedIndex].toEmail = newToEmail;
      this.setState({ selectedContacts: updatedSelected });
    }
  };

  saveDistributionGroup = () => {
    let that = this;
    let apiUrl = config.get("apiUrl");
    let token = localStorage.getItem("access_token");
    let bodyFormData = new FormData();

    if(this.state.groupObjectToEdit !== null) {
      bodyFormData.set("hID", "updateDistributionGroup");
      bodyFormData.set("uID", this.props.uid);
      bodyFormData.set("groupID", this.state.groupObjectToEdit.id);
      bodyFormData.set("groupName", this.state.distributionName);

    } else {
      bodyFormData.set("hID", "saveDistributionGroup");
      bodyFormData.set("uID", this.props.uid);
      bodyFormData.set("groupName", this.state.distributionName);
    }

    let npis = "";
    this.state.selectedContacts.forEach((contact) => {
      npis += contact.npi + ", ";
    });
    npis = npis.slice(0, -2); 
    bodyFormData.set("npis", npis);

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        console.log("new group:", response.data.result);
        let newGroups = [...that.state.distributionGroups];

        // Updating or adding?
        if(that.state.groupObjectToEdit !== null) {
          newGroups.forEach((group) => {
            if(group.id === that.state.groupObjectToEdit.id) {
              group.npis = npis;
              group.name = that.state.distributionName;
            }
          });
        } else {
          newGroups.push(response.data.result);
        }
        
        that.setState({showDistributionCreatedModal: true, distributionGroups: newGroups, showDistributionWarningModal: false});
      })
      .catch(function (response) {
        console.log("Error saving distribution group");
      });
  }

  editDistributionGroup = (key) => {
    // TODO: switch back to Save view, and load this group
    let selected = [];
    let group = this.state.distributionGroups[key];
    let contacts = [...this.state.contacts];
    let i = 0;

    this.setState({
      distributionView: 'save'
    }, () => {

      contacts.forEach((contact) => {
        if (!document.getElementById("contact-" + i).disabled) {
          if(group.npis.indexOf(contact.npi) >= 0) {
            selected.push(contact);
            document.getElementById("contact-" + i).checked = true;
          }
        }
        i = i + 1;
      });
  
      this.setState({
        groupObjectToEdit: group,
        distributionName: group.name,
        selectedContacts: selected
      });

    });
  }

  deleteDistributionGroup = () => {
    let that = this;
    let apiUrl = config.get("apiUrl");
    let token = localStorage.getItem("access_token");
    let bodyFormData = new FormData();
    bodyFormData.set("hID", "deleteDistributionGroup");
    bodyFormData.set("uID", this.props.uid);

    let groupId = this.state.distributionGroups[this.state.groupToDelete].id;
    bodyFormData.set("id", groupId);

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        console.log("deleted group:", response.data.result);
        let newGroups = [...that.state.distributionGroups];
        newGroups = newGroups.filter(
          (group) => group.id !== groupId
        );

        that.setState({
          groupToDelete: null,
          distributionGroups: newGroups,
          showDistributionDeleteModal: false,
        });
      })
      .catch(function (response) {
        console.log("Error saving distribution group");
      });
  }

  render() {
    const isGroupSelected =
      this.state.masOnlySelected ||
      this.state.ccAltEmailsSelected ||
      this.state.ccMasSelected ||
      this.state.allSelected;

    const renderEmailSelect = (row, repRegion, key, isGroupSelected) => {
      // TODO: these rules will change
      let isDisabled = false;
      isDisabled = row.hcpEmailCount >= 6 && repRegion !== "xxxxxxx";

      if (!isDisabled) {
        isDisabled = row.unsubscribed === true || row.unsubscribedNpi === true;
      }
      if (!isDisabled) {
        isDisabled = row.email === "";
      }
      if (!isDisabled) {
        isDisabled = this.props.distributionMode;
      }

      if (!isDisabled) {
        // Disabled if row is not selected
        let selected = [...this.state.selectedContacts];
        const ind = selected.findIndex((e) => e.id === row.id);
        isDisabled = ind < 0;
      }

      let emails = [row.email];
      if (row.hcp_email_1 !== "") {
        emails.push(row.hcp_email_1);
      }
      if (row.hcp_email_2 !== "") {
        emails.push(row.hcp_email_2);
      }
      if (row.hcp_email_3 !== "") {
        emails.push(row.hcp_email_3);
      }
      if (row.hcp_email_4 !== "") {
        emails.push(row.hcp_email_4);
      }

      return (

        <CustomSelect
          type="recipientEmail"
          options={emails}
          onSelect={this.selectDropdownEmail}
          isDisabled={(!this.props.profileMode && (isDisabled || isGroupSelected)) || this.props.profileMode}
          contact={row}
        />

        // <select
        //   value={row.toEmail}
        //   id={"email-select-" + key}
        //   onChange={(e) => {
        //     this.selectDropdownEmail(e, row);
        //   }}
        //   disabled={(!this.props.profileMode && (isDisabled || isGroupSelected)) || this.props.profileMode}
        // >
        //   {emails.map((aEmail, index) => (
        //     <option key={index} value={aEmail}>
        //       {aEmail}
        //     </option>
        //   ))}
        // </select>
      );
    };

    const renderRowInput = (
      hcpEmailCount,
      maEmailCount,
      unsubscribed,
      unsubscribedNpi,
      email,
      key,
      repRegion,
      type
    ) => {
      if ((hcpEmailCount >= 6 || (maEmailCount >= 6 && type==="ccma")) && repRegion !== "xxxxxxx" && !this.props.distributionMode) {
        return (
          <input
            type="checkbox"
            disabled
            className="checkbox-square has-limit"
            id={type + "-" + key}
          />
        );
      } else if (unsubscribed === true || unsubscribedNpi === true) {
        return (
          <input
            type="checkbox"
            disabled
            className="checkbox-square has-optout"
            id={type + "-" + key}
          />
        );
      } else if (email === "") {
        return (
          <input
            type="checkbox"
            disabled
            className="checkbox-square is-empty"
            id={type + "-" + key}
          />
        );
      } else {
        return (
          <input
            onClick={(event) => this.handleSelect(event, key, type)}
            type="checkbox"
            className="checkbox-square"
            id={type + "-" + key}
          />
        );
      }
    };

    return (
      <section className="app__recipients">
        {this.state.showContactCardModal &&
          this.state.editingContact !== null && (
            <ContactModal
              uid={this.props.uid}
              handleClose={this.closeContactCardModal}
              contact={this.state.editingContact}
              profileMode={this.props.profileMode ? true : false}
            />
          )}

        {this.state.showWarningModal && (
          <div className="app__modal contact__warning__modal">
            <div className="app__modal__container">
              <h3>
                WARNING: adjustments to the Collegium Connect database are only
                until the end of the month.
              </h3>
              <p>
                Changes to the Collegium Connect database are not permanent. To
                permanently change the contact information for one of your
                contacts <strong>FIRST</strong> make the adjustments in the Collegium CRM 
                database, and <strong>THEN</strong> update the Collegium Connect 
                database.
              </p>
              <p>
                By making adjustments to the Collegium Connect database you are
                acknowledging that they are temporary additions.
              </p>
              <button
                className="app__profile__button"
                onClick={(event) => {
                  this.setState({ showWarningModal: false });
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        )}

        {this.state.showDistributionWarningModal && (
          <div className="app__modal contact__warning__modal edit__group__warning__modal">
            <div className="app__modal__container">
              <h3>
                WARNING: adjustments to the Collegium Connect database are only
                until the end of the month.
              </h3>
              <p>
                If a member of a distribution group is removed from the Collegium CRM database 
                they will no longer be sent emails as part of a previous month's distribution 
                group. Distribution groups will carry over month-to-month with this exception.
              </p>
              <p>
                Changes to the Collegium Connect database are not permanent. To
                permanently change the contact information for one of your
                contacts <strong>FIRST</strong> make the adjustments in the Collegium CRM 
                database, and <strong>THEN</strong> update the Collegium Connect
                database.
              </p>
              <p>
                By making adjustments to the Collegium Connect database you are
                acknowledging that they are temporary additions.
              </p>
              <button
                className="app__profile__button"
                onClick={(event) => {
                  this.saveDistributionGroup();
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        )}

        {this.state.showDistributionCreatedModal && (
          <div className="app__modal contact__warning__modal delete__group__warning__modal">
          <div className="app__modal__container">
            <p>Your distribution group has been saved!</p>
            <button
              className="app__profile__button"
              onClick={(event) => {
                this.setState({
                  distributionName: "",
                  selectedContacts: [],
                  distributionView: "view",
                  allSelected: false,
                  groupObjectToEdit: null,
                  showDistributionCreatedModal: false
                });
              }}
            >
              OK
            </button>
          </div>
        </div>
      
        )}

        {this.state.showDistributionDeleteModal && (
          <div className="app__modal contact__warning__modal delete__group__warning__modal">
            <div className="app__modal__container">
              <p>Are you sure you want to delete this distribution group?</p>
              <button
                className="app__profile__button"
                onClick={(event) => {
                  this.deleteDistributionGroup();
                }}
              >
                Delete
              </button>
              <button
                className="app__profile__button cancel"
                onClick={(event) => {
                  this.setState({groupToDelete: null, showDistributionDeleteModal: false});
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {this.state.showClearSelectionsModal && (
          <div className="app__modal contact__warning__modal delete__group__warning__modal">
            <div className="app__modal__container">
              <h3>Warning:</h3>
              <p>Switching views will clear any currently selected recipients</p>
              <button
                className="app__profile__button"
                onClick={(event) => {
                  let newView = this.state.sendView === "all" ? "distribution" : "all";

                  this.props.handleRecipientsUpdated([]); // clear parent

                  this.setState({
                    selectedContacts: [],
                    allSelected: false,
                    masOnlySelected: false,
                    ccMasSelected: false,
                    ccAltEmailsSelected: false,
                    selectedDistributionGroups: [],
                    sendView: newView,
                    showClearSelectionsModal: false
                  });
                }}
              >
                Proceed
              </button>
              <button
                className="app__profile__button cancel"
                onClick={(event) => {
                  this.setState({showClearSelectionsModal: false});
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        <div className="app__recipients__container">
          <div
            className="app__recipients__search"
            style={{ minHeight: this.props.profileMode ? "auto" : this.props.distributionMode ? "190px" : "190px" }}
          >
            {!this.props.distributionMode && 
              <h4 className="app__recipients__heading">Search</h4>
            }

            {this.props.distributionMode && 
              <h4 className="app__recipients__heading">Name Distribution Groups</h4>
            }

            {!this.props.distributionMode && 
              <input
                type="text"
                className="app__recipients-input"
                placeholder="[Dr. First Name, Last Name] ; [Dr. First Name, Last Name]"
                onKeyUp={(event) => {
                  this.handleSearchKeyUp(event);
                }}
              />
            }

            {this.props.distributionMode && 
              <input
                type="text"
                className="app__recipients-input"
                value={this.state.distributionName}
                onChange={(e) => {
                  this.setState({distributionName: e.target.value});
                }}
              />
            }

            <div
              className="app__recipients-selected"
              style={{ display: this.props.profileMode ? "none" : "block" }}
            >
              <div className="app__recipients-selected__title">
                Selected Recipients ({this.state.selectedContacts.length})
              </div>
              <div
                className="app__recipients-selected__list"
                id="selectedContacts"
              >
                {this.state.selectedContacts.map((row, key) => (
                  <span key={"contact-" + key}>{row.toEmail}, </span>
                ))}
              </div>
            </div>

            {this.props.distributionMode &&
              <div>
                {this.state.distributionView === 'save' &&
                <button className="app__recipients-dist-save" onClick={(e) => this.setState({showDistributionWarningModal: true})} 
                disabled={this.state.selectedContacts.length === 0 || this.state.distributionName.trim() === ""}>
                  {this.state.groupObjectToEdit === null ? "Save Group" : "Update Group"}
                </button>
                }
                <div className="app__recipients-dist-actions">
                  <span>Distribution Groups</span>
                  <button className={`${this.state.distributionView === 'save' ? "active" : ""}`} onClick={(e) => this.setState({distributionView: 'save'})}>Create</button> | 
                  <button className={`${this.state.distributionView === 'view' ? "active" : ""}`} onClick={(e) => this.setState({distributionView: 'view'})}>View</button>
                </div>
              </div>
            }

            {this.props.sendMode &&
              <div>
                <div className="app__recipients-dist-send-actions">
                  <button className={`${this.state.sendView === 'all' ? "active" : ""}`} onClick={(e) => this.setState({showClearSelectionsModal: true})}>All Contacts</button> | 
                  <button className={`${this.state.sendView === 'distribution' ? "active" : ""}`} onClick={(e) => this.setState({showClearSelectionsModal: true})}>Distribution Groups</button>
                </div>
              </div>
            }
          </div>

          <div className="app__recipients__list">
              <div className="app__recipients__commands">
              {this.props.sendMode && this.state.sendView === "all" && 
                <div className="section-1">
                  <span className="subtitle">HCP Group Selections</span>
                  <div>
                    {/* <button
                      onClick={(e) => this.clickAllPrimary()}
                      className={`${this.state.allSelected ? "active" : ""}`}
                    >
                      All Primary
                    </button> */}
                    <button
                      onClick={(e) => this.clickCCAltEmails()}
                      className={`${
                        this.state.ccAltEmailsSelected ? "active" : ""
                      }`}
                    >
                      CC Alt Email
                    </button>
                  </div>
                </div>
                }
                {this.props.sendMode && this.state.sendView === "all" && 
                <div className="section-2">
                  <span className="subtitle">
                    Medical Assistant (MA) Group Selections
                  </span>
                  <div>
                    <button
                      onClick={(e) => this.clickCCMAs()}
                      className={`${this.state.ccMasSelected ? "active" : ""}`}
                    >
                      CC MAs
                    </button>
                    <button
                      onClick={(e) => this.clickMAsOnly()}
                      className={`${
                        this.state.masOnlySelected ? "active" : ""
                      }`}
                    >
                      MAs Only
                    </button>
                    <span className="footnote">
                      (primary HCP Email cc'd automatically)
                    </span>
                  </div>
                </div>
                }

                {!this.props.distributionMode && (this.props.sendMode && this.state.sendView === "all") && 
                  <div className={`${
                    this.props.profileMode || this.props.distributionMode ? "section-3-profile" : "section-3"
                  }`}>
                    {!isGroupSelected && (
                      <p>
                        Select an account and double click row for full contact information and to customize email recipient
                      </p>
                    )}
                    {isGroupSelected && (
                      <p>
                        Customizing email recipients by double clicking is
                        disabled when a group selection or non-primary email is
                        active.
                      </p>
                    )}
                  </div>
                }

                {this.props.distributionMode && this.state.distbutionView === "save" &&
                  <div className="section-3-profile">
                    {!isGroupSelected && (
                      <p>
                        Select an account to add to the distribution list
                      </p>
                    )}
                  </div>
                }

              </div>

            {this.props.distributionMode && this.state.distributionView === 'view' && (
              <table className="app__recipients__list-table dist-table">
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
                {this.state.distributionGroups.map((row, key) => (
                  <tr key={"dist-" + key}>
                    <td>{row.name}</td>
                    <td><FontAwesomeIcon icon={faEdit} onClick={(e) => this.editDistributionGroup(key)}/></td>
                    <td><FontAwesomeIcon icon={faTimesCircle} onClick={(e) => {
                      this.setState({showDistributionDeleteModal: true, groupToDelete: key});
                    }}/></td>
                  </tr>
                ))}
              </tbody>
              </table>  
            )}

            {this.props.sendMode && this.state.sendView === 'distribution' && (
              <table className="app__recipients__list-table dist-table">
              <tbody>
                <tr>
                  <th>&nbsp;</th>
                  <th>Name</th>
                  <th>CC MAs</th>
                </tr>
                {this.state.distributionGroups.map((row, key) => (
                  <tr key={"dist-" + key}>
                    <td>
                    <input
                      onClick={(event) => this.handleSelectDistributionGroup(event, key)}
                      type="checkbox"
                      className="checkbox-square"
                      id={"dist-send-" + key}
                      checked={this.state.selectedDistributionGroups.includes(key)}
                    />
                    </td>
                    <td>{row.name}</td>
                    <td>
                    <input
                      onClick={(event) => this.handleSelectCcmasDistributionGroup(event, key)}
                      type="checkbox"
                      className="checkbox-square"
                      id={"dist-ccmas-" + key}
                      disabled={!this.state.selectedDistributionGroups.includes(key)}
                    />
                    </td>
                  </tr>
                ))}
              </tbody>
              </table>  
            )}

            {((this.props.distributionMode && this.state.distributionView === 'save') || (this.props.sendMode && this.state.sendView === 'all') || this.props.profileMode) && (
              <table className="app__recipients__list-table recipient-dist-table">
                <tbody>
                  <tr
                    style={{ display: this.props.profileMode || this.props.distributionMode ? "none" : "block" }}
                  >
                    <th
                      colSpan="2"
                      className="select_all"
                      onClick={(e) => this.selectAll()}
                    >
                      {/* <span>
                        {this.state.allSelected === true
                          ? "Deselect All"
                          : "Select All"}
                      </span> */}
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th colSpan="3" className="sort_by">
                      <span>Sort by column</span>
                    </th>
                  </tr>
                  <tr>
                    <th
                      style={{
                        display: this.props.profileMode ? "none" : "block",
                      }}
                    ></th>
                    <th
                      className="asc"
                      onClick={(event) => this.sortArray(event, "firstName")}
                    >
                      <span>F Name</span>
                    </th>
                    <th onClick={(event) => this.sortArray(event, "lastName")}>
                      <span>L Name</span>
                    </th>
                    <th onClick={(event) => this.sortArray(event, "specialty")}>
                      <span>Specialty</span>
                    </th>
                    <th>
                      <span>Email Address</span>
                    </th>
                    <th
                      style={{
                        display: this.props.profileMode || this.props.distributionMode ? "none" : "block",
                      }}
                    >
                      <span>CC MAs</span>
                    </th>
                    <th onClick={(event) => this.sortArray(event, "city")}>
                      <span>City</span>
                    </th>
                    <th onClick={(event) => this.sortArray(event, "state")}>
                      <span>State</span>
                    </th>
                    <th onClick={(event) => this.sortArray(event, "zip")}>
                      <span>Zip</span>
                    </th>
                  </tr>
                  {this.state.contacts.map((row, key) => (
                    <tr
                      key={key}
                      className={`${
                        row.unsubscribed
                          ? "has-optout"
                          : row.unsubscribedNpi ? "has-optout-npi"
                          : row.hcpEmailCount >= 6 && this.props.repRegion !== "xxxxxxx" && !this.props.distributionMode
                          ? "has-limit"
                          : row.email === ""
                          ? "is-empty"
                          : ""
                      }`}
                      onDoubleClick={(event) => {
                        console.log("onDoubleClick", row);

                        // Disable edit during distribution mode
                        if(this.props.distributionMode) {
                          return;
                        }

                        let selected = [...this.state.selectedContacts];
                        const selectedIndex = selected.findIndex(
                          (e) => row.id === e.id
                        );
                        if (selectedIndex >= 0 || this.props.profileMode) {
                          if (row.toEmail !== row.email) {
                            alert(
                              "Cannot edit contact when non-primary email address is selected."
                            );
                          } else if (!isGroupSelected) {
                            this.setState({
                              editingContact: row,
                              showContactCardModal: true,
                            });
                          } else {
                            console.log("CANT double click");
                          }
                        }
                      }}
                    >
                      <td
                        style={{
                          display: this.props.profileMode ? "none" : "block",
                        }}
                      >
                        {renderRowInput(
                          row.hcpEmailCount,
                          row.maEmailCount,
                          row.unsubscribed,
                          row.unsubscribedNpi,
                          row.email,
                          key,
                          this.props.repRegion,
                          "contact"
                        )}
                      </td>
                      <td>{row.firstName}</td>
                      <td>{row.lastName}</td>
                      <td>{row.specialty}</td>
                      <td>
                        {renderEmailSelect(
                          row,
                          this.props.repRegion,
                          key,
                          isGroupSelected
                        )}
                      </td>
                      <td
                        style={{
                          display: this.props.profileMode  || this.props.distributionMode ? "none" : "block",
                        }}
                      >
                        {renderRowInput(
                          row.hcpEmailCount,
                          row.maEmailCount,
                          row.unsubscribed,
                          row.unsubscribedNpi,
                          row.email,
                          key,
                          this.props.repRegion,
                          "ccma"
                        )}
                      </td>
                      <td>{row.city}</td>
                      <td>{row.state}</td>
                      <td>{row.zip}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

            )}
          </div>
        </div>
      </section>
    );
  }
}

export default Recipients;
